.formHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.formHeader h3 {
  color: #0068ff;
  font-size: 8vw;
  text-align: center;
  line-height: 8vw;
  margin-top: 3vw;
  padding-bottom: 6vw;
}
.Form {
  background-color: #f2f5fe;
  padding-bottom: 12vw;
  height: 120vh;
}

.formHeader h4 {
  text-align: center;
  font-size: 4.5vw;
  width: 68vw;
  margin-top: 2vw;
  padding-bottom: 8vw;
}
.form {
  filter: drop-shadow(0px 6px 25px rgba(143, 201, 255, 0.51));
  background: #b1deff;
  padding: 3vw;
  border-radius: 5vw 5vw 5vw 0;
  width: 92%;
  margin: 0 auto;
  margin-top: 5vw;
}
.formBody {
  border-radius: 5vw;
  background: #fff;
  padding: 5vw;
  display: flex;
  flex-direction: column;
  padding-top: 6vw;
}
.inputsForm input {
  padding: 4vw 4vw;
  font-size: 5vw;
  color: #586f8b;
  font-weight: bold;
  width: 100%;
  background: #daeafe;
  border-radius: 8vw 3vw 8vw 0;
  outline: none;
  border: none;
}
.inputsForm p {
  font-size: 3.5vw;
  margin-left: 4vw;
  margin-bottom: 1vw;
  font-weight: bold;
}
.inputsForm {
  margin-bottom: 6vw;
}
.sendForm {
  background: #7ec800;
  text-transform: uppercase;
  border-radius: 8vw 3vw 8vw 0;
  padding: 5vw 4vw;
  width: 100%;
  color: #fff;
  font-size: 4vw;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1vw;
}
