.serviceItem {
  background: #0068ff;
  filter: drop-shadow(0px 6px 25px rgba(0, 133, 255, 0.72));
  padding: 1.5vw;
  border-radius: 0 8vw 8vw 8vw;
  width: 94%;
  margin: 0 auto;
  margin-top: 5vw;
}
.services {
  background: #d7f5ff;
  padding-bottom: 8vw;
}
.serviceItemHeader {
  background: #fff;
  border-radius: 0 8vw 3vw 8vw;
  padding: 1.5vw;
  display: flex;
  align-items: center;
}
.ask {
  filter: drop-shadow(0px 2px 11px rgba(101, 197, 67, 0.72));
  background: #7ec800;
  text-transform: uppercase;
  color: #fff;
  padding: 3vw 5vw;
  font-size: 3.2vw;
  text-align: center;
  width: fit-content;
  border-radius: 0 8vw 3vw 8vw;
  font-weight: 600;
  float: right;
}

@keyframes movingFlare {
  0% {
    left: -20vw;
    margin-left: 0px;
  }
  30% {
    left: 110%;
    margin-left: 80px;
  }
  100% {
    left: 110%;
    margin-left: 80px;
  }
}
.serviceItemHeader p {
  text-transform: uppercase;
  font-size: 3.2vw;
  font-weight: 700;
  width: 60vw;
}
.serviceItemHeader svg {
  width: 8vw;
  margin-left: 3vw;
  margin-right: 2vw;
}
.serviceUl {
  color: #fff;
  font-size: 4vw;
  padding-left: 8vw;
  padding-top: 4vw;

  padding-bottom: 3vw;
  transition: 1.5s;
}
.serviceUl li {
  margin-bottom: 1vw;
}
.spisok {
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 3.5vw;
  padding: 2vw;
  margin-left: 1.5vw;
}
.spisok svg {
  margin-left: 2vw;
  margin-top: 0.4vw;
}
