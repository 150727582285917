header {
  display: flex;
  align-items: center;
  padding: 4vw;
  justify-content: space-between;
}
.oval {
  background-size: cover;
  background-color: #f2f5fe;
  background-repeat: no-repeat;
  background-position-y: -10vw;
}
.Typewriter {
  font-size: 8.5vw;
  margin-top: 4vw;
  color: #0068ff;
  font-weight: 600;
  text-align: center;
  height: 20vw;
  padding-bottom: 10vw;
}
.manager {
  margin-left: 2vw;
  width: 11vw;
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: 0px 11px 27px 0px rgba(60, 129, 233, 0.54);
}
.logo {
  width: 12vw;
  height: 12vw;
}
.send {
  display: flex;
  align-items: center;
  background: #0068ff;
  border-radius: 5vw;
  color: #fff;
  font-weight: 600;
  padding: 3vw 3.5vw;
  font-size: 3.5vw;

  box-shadow: 0px 11px 27px 0px rgba(60, 129, 233, 0.54);
}
.send img {
  width: 3vw;
  margin-right: 2vw;
}
.blocks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 4vw;
  width: 94%;
  grid-column-gap: 4vw;
  grid-row-gap: 4vw;
}
.main_content {
  position: relative;
  padding-bottom: 19vw;
  background: #f2f5fe;
  padding-top: 8vw;
}
.refs {
  display: flex;
  align-items: center;
  position: relative;
  right: -30vw;
  transition: 0.5s;
}
.refs img {
  width: 10vw;
  margin: 0 1.5vw;
  display: block;
}
.refs a {
  display: block;
}
.info {
  width: 10vw;
  height: 10vw;
  background: #a39de3;
  text-transform: uppercase;
  border-radius: 50%;
  font-size: 3.5vw;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  top: 44.5%;
  left: 45%;
}
.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 2vw;
  border-radius: 10vw 10vw 10vw 0;

  width: 100%;
  justify-content: space-between;
}
.block:nth-child(n) {
  background: #0068ff;
  filter: drop-shadow(0px 6px 25px rgba(0, 133, 255, 0.72));
}
.block:nth-child(2n) {
  background: #cf74ff;
  filter: drop-shadow(0px 6px 25px rgba(229, 126, 255, 0.72));
}
.block:nth-child(3n) {
  background: #f9c912;
  filter: drop-shadow(0px 6px 25px #ffe690);
}
.block:nth-child(4n) {
  background: #eb7170 !important;
  filter: drop-shadow(0px 6px 25px rgba(255, 138, 138, 0.72));
}
.block img,
.block svg {
  width: 31vw;
  margin-top: 1vw;
}
.Main header {
  padding-bottom: 15vw;
}
.block:nth-child(2n) {
  border-radius: 10vw 10vw 0vw 10vw;
}
.block div {
  border-radius: 10vw 5vw 10vw 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 3vw 3vw;
  margin-top: -4vw;
  padding-top: 5.5vw;
  text-transform: uppercase;
  justify-content: flex-start;
}
.block:nth-child(2n) div {
  border-radius: 5vw 10vw 0vw 10vw;
  justify-content: flex-end !important;
}
.block div h4 {
  font-size: 5vw;
}
.block div p {
  font-size: 2.7vw;
  margin-bottom: 0.5vw;
}
.block:nth-child(2n) div * {
  text-align: right;
}
